import React, { FC, useMemo } from 'react'
import { LabelProps, TagProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import NewCardHeadline from '@sport1/news-styleguide/NewCardHeadline'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import Breakpoint from '@/utils/breakpoints/Breakpoint'
import { SizeTypes } from '@/types/size'
import { onArrowPress } from '@/helpers/pageReferringWidgetHelper'
import { useIsOnTeamPage } from '@/helpers/teamHelper'

/**
 * SPORT1 REFRESH TODO: Potential clean up, correct code mistakes, prettify - was a quick setup before the presentation (after going live needed improvements)
 * make NewCardHeadline type work with responsive variants so we can get rid of this breakpoints + remove "New" from "NewCardHeadline"
 */

interface CardProps extends SystemSpacingsProps {
    title: string
    titleHref?: string
    hint?: string
    tag?: TagProps | LabelProps
    lazy?: boolean
    imageUrl?: string
    onPress?: () => void
    showHintText?: boolean
    testID?: string
}

const TeaserCardHeadline: FC<CardProps> = ({
    title,
    tag,
    marginX,
    titleHref,
    lazy,
    onPress,
    showHintText,
    testID,
}) => {
    const isOnTeamPage = useIsOnTeamPage()
    const cardHeadlineLink = useMemo(() => {
        if (titleHref) {
            return titleHref
        } else if (tag) {
            return tag.href
        }
    }, [tag, titleHref])

    const onArrowPressed = () => {
        onPress
        isOnTeamPage ? onArrowPress() : null
    }

    return (
        <NonFlexingContainer
            testID={testID}
            marginX={marginX}
            spaceBottom="standard"
            backgroundColor="transparent"
        >
            {cardHeadlineLink ? (
                <>
                    <Breakpoint lazy={lazy} device={[SizeTypes.TABLET, SizeTypes.DESKTOP]}>
                        <NonFlexingContainer display={['none', 'flex', 'flex']}>
                            <NewCardHeadline
                                cardHeadlineType={
                                    title === 'Newsticker' || 'Heute Live'
                                        ? 'cardHeadlineS'
                                        : 'cardHeadlineM'
                                }
                                text={showHintText && tag ? tag.title : title}
                                rightIconName="scroll-right"
                                onPress={onArrowPressed}
                                href={cardHeadlineLink}
                                testID="teaser-card-headline-with-hint-and-link"
                            />
                        </NonFlexingContainer>
                    </Breakpoint>
                    <Breakpoint lazy={lazy} device={SizeTypes.MOBILE}>
                        <NonFlexingContainer display={['flex', 'none', 'none']}>
                            <NewCardHeadline
                                cardHeadlineType="cardHeadlineS"
                                rightIconName="scroll-right"
                                onPress={onArrowPressed}
                                text={title || (tag ? tag.title : '')}
                                href={cardHeadlineLink}
                                testID="teaser-card-headline-with-link"
                            />
                        </NonFlexingContainer>
                    </Breakpoint>
                </>
            ) : (
                // Because of too much work for BE for small change we use hardCoded approach
                // for selectableText. TODO: This has to be fixed when backend is fixed
                <NonFlexingContainer>
                    <>
                        <Breakpoint lazy={lazy} device={[SizeTypes.TABLET, SizeTypes.DESKTOP]}>
                            <NonFlexingContainer display={['none', 'flex', 'flex']}>
                                <NewCardHeadline
                                    cardHeadlineType="cardHeadlineM"
                                    text={
                                        new RegExp(/-w-/i).test(title)
                                            ? title.replace(/-w-/i, '').trim()
                                            : title
                                    }
                                    testID="teaser-card-headline"
                                />
                            </NonFlexingContainer>
                        </Breakpoint>
                        <Breakpoint lazy={lazy} device={SizeTypes.MOBILE}>
                            <NonFlexingContainer display={['flex', 'none', 'none']}>
                                <NewCardHeadline
                                    cardHeadlineType="cardHeadlineS"
                                    text={
                                        new RegExp(/-w-/i).test(title)
                                            ? title.replace(/-w-/i, '').trim()
                                            : title
                                    }
                                    testID="teaser-card-headline"
                                />
                            </NonFlexingContainer>
                        </Breakpoint>
                    </>
                </NonFlexingContainer>
            )}
        </NonFlexingContainer>
    )
}

export default TeaserCardHeadline
