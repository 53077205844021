import React, { useMemo, VFC } from 'react'
import { ActionButtonProps, LabelProps, TagProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import ActionButtons from '@/components/ActionButtons'
import { GLOBAL_LAYOUT_COMPONENT_SPACE } from '@/utils/content/constants'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

const ACTIVE_ACTION_BUTTON_VALUES = [
    'web-gameplan-sport',
    'web-standings-sport',
    'web-conference-ticker',
]

const ActionButtonsFooter: VFC<Pick<TagProps | LabelProps, 'title' | 'navigationItems'>> = ({
    title,
    navigationItems,
}) => {
    const actionButtons = useMemo(() => {
        const actionButtons: ActionButtonProps[] = []
        navigationItems?.forEach(item => {
            if (ACTIVE_ACTION_BUTTON_VALUES.includes(item.value)) {
                actionButtons.push({ title: item.title, href: item.href || '' })
            }
        })
        return actionButtons
    }, [navigationItems])

    if (!actionButtons.length) {
        return null
    }

    return (
        <>
            <NonFlexingContainer
                backgroundColor="pearl"
                paddingX={GLOBAL_LAYOUT_COMPONENT_SPACE}
                paddingTop={GLOBAL_LAYOUT_COMPONENT_SPACE}
                paddingBottom={['spacing-6', 'spacing-7', 'spacing-9']}
                testID="action-button-footer"
            >
                <Font
                    fontVariant="Display-S"
                    fontFamilyVariant="Sport1-CondensedBlackItalic"
                    nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
                    paddingBottom="spacing-7"
                >{`Alles zu ${title}`}</Font>
                <ActionButtons actionButtons={actionButtons} />
            </NonFlexingContainer>
        </>
    )
}

export default ActionButtonsFooter
