import React, { FC, memo } from 'react'
import { ActionButtonProps } from '@sport1/types/web'
import { ResponsiveType, SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import MobileActionButtons from '@/components/ActionButtons/Mobile'
import NonMobileActionButtons from '@/components/ActionButtons/NonMobile'

export interface ActionButtonsProps extends SystemSpacingsProps {
    actionButtons: ActionButtonProps[]
    backgroundColor?: 'TRANSPARENT' | 'pearl' | 'onyx'
    onPressCallback?: () => void
    spaceBetween?: ResponsiveType<number>
    testID?: string
    buttonVariant?: 'primary' | 'secondary' | 'tertiary' | 'highlight'
}

const ActionButtons: FC<ActionButtonsProps> = ({
    backgroundColor = 'TRANSPARENT',
    actionButtons,
    buttonVariant = 'tertiary',
    ...rest
}) => {
    if (!actionButtons.length) return null
    return (
        <>
            <MobileActionButtons
                actionButtons={actionButtons}
                backgroundColor={backgroundColor}
                buttonVariant={buttonVariant}
                {...rest}
            />
            <NonMobileActionButtons
                actionButtons={actionButtons}
                backgroundColor={backgroundColor}
                buttonVariant={buttonVariant}
                {...rest}
            />
        </>
    )
}

export default memo(ActionButtons)
