import React, { FC, PropsWithChildren } from 'react'
import { TagProps, LabelProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { PixelType, ResponsiveType, SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import clsx from 'clsx'
import TeaserCardHeadline from './Headline'

interface CardProps extends SystemSpacingsProps {
    backgroundColor?: 'pearl' | 'onyx'
    hint?: string
    lazy?: boolean
    disableContentSpaceX?: boolean
    minHeight?: ResponsiveType<number | PixelType>
    tag?: TagProps | LabelProps
    testID?: string
    title?: string
    titleHref?: string
    imageUrl?: string
    onPress?: () => void
    /**use this flag on HorizontalTeaserCards, to show hint text when no title exists */
    showHintText?: boolean
}

const Card: FC<CardProps & PropsWithChildren> = ({
    children,
    backgroundColor,
    hint,
    lazy,
    disableContentSpaceX,
    marginBottom,
    minHeight,
    tag,
    testID,
    title,
    titleHref,
    imageUrl,
    showHintText,
    onPress,
}) => {
    return (
        <NonFlexingContainer marginBottom={marginBottom} testID={testID}>
            <NonFlexingContainer
                paddingBottom="spacing-11"
                backgroundColor={backgroundColor}
                minHeight={minHeight}
                testID={`${testID}-wrapper`}
                className={clsx({ 'px-3 sm:px-6 md:px-3 lg:px-0': !disableContentSpaceX })}
            >
                {(title || showHintText) && (
                    <TeaserCardHeadline
                        title={title || ''}
                        titleHref={titleHref}
                        onPress={onPress}
                        hint={hint}
                        tag={tag}
                        lazy={lazy}
                        imageUrl={imageUrl}
                        showHintText={showHintText}
                        testID={`${testID}-teaser-card-headline`}
                    />
                )}
                {children}
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default Card
