import React, { FC, memo, useMemo } from 'react'
import { HeadlineProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font, { FontProps } from '@sport1/news-styleguide/Font'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import { SizeTypes } from '@/types/size'
import { useTeamContext } from '@/context/TeamContext'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

type Props = {
    component: Pick<HeadlineProps, 'text'>
    noStyle?: boolean
    testID?: string
}

const baseStyle: SystemSpacingsProps = {
    paddingX: ['spacing-6', 'spacing-6', 'spacing-9'],
    paddingTop: ['spacing-6', 'spacing-9', 'spacing-10'],
    paddingBottom: 'spacing-6',
}

const baseTextProps: Pick<FontProps, 'color' | 'uppercase'> = {
    color: 'onyx',
    uppercase: true,
}

const Headline: FC<Props> = ({ component, noStyle, testID }) => {
    const { team } = useTeamContext()
    const { currentDevice } = useMediaQueryContext()
    const { text } = component

    const headline = useMemo((): string => {
        let result = text
        if (text.includes('{teamName}')) {
            result =
                currentDevice === SizeTypes.MOBILE
                    ? text.replace('{teamName}', team.shortName ?? '')
                    : text.replace('{teamName}', team.name ?? '')
        }

        return result
    }, [currentDevice, team?.name, team?.shortName, text])

    return (
        <NonFlexingContainer
            testID={testID}
            {...(noStyle ? {} : baseStyle)}
            paddingBottom={headline === 'Newsticker' ? 'spacing-none' : 'spacing-6'}
        >
            <Font
                fontFamilyVariant="Sport1-CondensedBlackItalic"
                nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
                fontVariant="Headline-M"
                numberOfLines={2}
                testID={`${testID}-text`}
                {...baseTextProps}
            >
                {headline}
            </Font>
        </NonFlexingContainer>
    )
}

export default memo(Headline)
